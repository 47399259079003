export class WineDetail {
  alcohol: number = 0;
  color: string = '';
  description: string = '';
  favourite: boolean = false;
  featured: boolean = false;
  id: number = 0;
  imageCount: number = 0;
  qvevri: boolean = false;
  sparkling: boolean = false;
  sweetness: string = '';
  taste: boolean = false;
  thumbnailIndex: number = 0;
  title: string = '';
  varietyName: string = '';
  year: number = 0;
  imgUrl: string = '';
  brandName: string = '';
  regionName: string = '';
  type: string = '';
  wineryName: string = '';
}
